import {mapActions, mapGetters} from "vuex";
import axios from "@/axios";
import html2pdf from "html-to-pdf-js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {get} from "lodash";
import {DateTime} from "luxon";
import neoAnalyse from "@shared/components/analyse";
import NeoLoader from "@shared/components/loader";
import WorldCompliance from "./worldCompliance.vue";

import {EventBus} from "@/main.js";
// import Loader from "@/components/loader";
import adverseMixin from "@shared/tools/mixins/adverseMixin";
import printDataMixin from "@shared/tools/mixins/printDataMixin";
import highlighterMixin from "@shared/mixins/highlighterMixin";
import { checkPermission } from "@shared/utils/functions";

export default {
    components: {
        neoAnalyse,
        NeoLoader,
        WorldCompliance,
        Loading,
        // "neo-loader": Loader,
    },
    data() {
        return {
            urlFields: ["related url", "locationurl"],
            isPrintLoader: false,
            isMediaLoading: false,
            isMonitoringTab: false,
            fullview: false,
            // TODO translate if will be used
            tabs: [
                {
                    name: "Current",
                    value: "current",
                },
                {
                    name: "Old",
                    value: "old",
                },
            ],
        };
    },
    mixins: [adverseMixin, printDataMixin, highlighterMixin],
    props: {
        content: {
            type: Object,
        },
        isResultExpanded: {
            type: Boolean,
        },
        isSavedLoading: {
            type: Object,
            default: {},
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
        isComparisonModeOn: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.isMonitoringTab = this.$route.name === "monitoring";
        if (this.isComparisonModeOn) {
            this.content.detailsExpanded = false;
            this.$refs[`${this.content.key}ComponentsRef`].handleExpandAll(true, false);
            setTimeout(() => {
                this.highlightChanges();
            }, 1000);
        }
    },
    watch: {
      
    },
    computed: {
        ...mapGetters(["getEntitiesRelationsAttributesData"]),
        expandedCard() {
            return this.content;
        },
        isMedia() {
            return this.adverseCard?.doc.media && this.adverseCard?.doc.media.length > 0;
        },
        adverseCard() {
            return this.expandedCard?.api_all_data ?? {};
        },
        adverseCardKey() {
            return this.expandedCard?.key;
        },
        getSavedClasses() {
            let classes = "";
            if (this.isSavedLoading.loading && this.isSavedLoading.loadingCardIndex === this.content.api_all_data.doc_id) classes += " opacity-40 cursor-not-allowed ";
            if (this.content.api_all_data.saved) classes += " text-blue-700 font-semibold  text-xs bg-blue-200 ";
            else classes += " bg-blue-200 text-blue-800 ";
            return classes;
        },
        selectedTab() {
            return this.isOldVersionSelected ? "old" : "current";
        },
    },
    methods: {
        ...mapActions(["setSelectedEntityData", "showAddEntityPanel"]),
        checkPermission,
        get,
        getIdentifierMapping(key, idx) {
            let mapping = "";
            switch (this.adverseCardKey) {
                case "complyAdvantage":
                    if (key === "name") mapping = "['data']['name']";
                    else if (key === "aliases") mapping = `['data']['aka'][${idx}]['name']`;
                    break;
                case "worldCheck":
                    if (key === "name") mapping = "['data']['first_name'] ['data']['last_name']";
                    else if (key === "aliases") mapping = `['data']['aliases'][${idx}]`;
                    break;
                case "dowJones":
                    if (key === "name") mapping = "['data']['name'][0]";
                    else if (key === "aliases") mapping = `['data']['aka'][${idx}]`;
                    break;
                case "offshoreLeaks":
                    if (key === "name") mapping = "['data']['name']";
                    break;
                case "lexisBridger":
                    if (key === "name") mapping = "['data']['EntityDetails']['Name']['Full']";
                    break;
            }
            return mapping;
        },

        getFormattedTime(ts, format) {
            let date = DateTime.fromISO(ts);
            return format ? date.toFormat(format) : date.toFormat("EEE, dd MMM yyyy, hh:mm:ss a");
        },
        parseTypes(data) {
            if (data?.trim()) return data?.split(",")?.map((e) => e.replace(/-/g, " ")?.replace(/None/g, "Others"));
            else return [];
        },
        getEntityType(result) {
            const type = result.api_all_data.EntryType;
            const key = result.key;
            if (key === "worldCompliance") {
                if (type === "Individual") return type;
                else return "Business";
            } else {
                return type;
                // if (type === "Individual" || ) return "Person";
                // else return "Company";
            }
        },
        changedResult(og_value, key) {
            let result = {};
            let changeKey = get(this.adverseCard?.change_history ?? {}, key, []);
            if (changeKey.length) {
                result = [];
                return result;
            } else return {text: og_value};
        },
        async printDocuments(id, fileName, key, noPrint) {
            this.isPrintLoader = true;
            let htmlPDF = "";
            if (key == "complyAdvantage") {
                htmlPDF = await this.$refs.complyAdvantageComponentsRef.printPage(id, fileName, noPrint);
            } else if (key == "worldCheck") {
                htmlPDF = await this.$refs.worldCheckComponentsRef.printPageWorldClock(id, fileName, noPrint);
            } else if (key == "dowJones") {
                this.adverseCard.sources.forEach(async (_, index) => {
                    htmlPDF += await this.$refs.dowJonesComponentsRef[index].printPageDowJones(id, fileName, this.adverseCard.sources.length !== index + 1, noPrint);
                });
            } else if (key == "offshoreLeaks") {
                htmlPDF = await this.$refs.offshoreLeaksComponentsRef.printPageIcij(id, fileName, noPrint);
            } else if (key == "lexisBridger") {
                htmlPDF = await this.$refs.lexisBridgerComponentsRef.printPageLexis(id, fileName, noPrint);
            }
            else if (key == "worldCompliance") {
                htmlPDF = await this.$refs.lexisBridgerComponentsRef.printPageLexis(id, fileName, noPrint);
            }
            return htmlPDF;
        },
        updatePrintStatus() {
            this.isPrintLoader = !this.isPrintLoader;
        },
        handleResultSaveData(result) {
            EventBus.$emit("handleResultSave", result);
        },

        /// @def DowJones MultiLevel Sources
        /// @src Source - ame,soe,wl
        expandedDowJones(src) {
            if (this.adverseCard[src] !== undefined) {
                return true;
            } else return false;
        },
        dowJonesHeader(srx) {
            if (srx === "wl") return this.$t('components.saas.adverse_media.watch_list');
            else if (srx === "ame") return this.$t('components.saas.adverse_media.adverse_media_entities');
            else if (srx === "soe") return this.$t('components.saas.adverse_media.state_owned_media');
        },
        async createPDF() {
            document.getElementById("close-button").style.display = "none";
            [...document.querySelectorAll("#print-button")].forEach((print) => {
                print.style.display = "none";
            });

            const htmlContent = await this.printDocuments("printDataSection", this.expandedCard.name, this.expandedCard.key, true);

            [...document.querySelectorAll("#print-button")].forEach((print) => {
                print.removeAttribute("style");
            });

            document.getElementById("close-button").removeAttribute("style");

            this.isMediaLoading = true;

            let doc = null;

            await new Promise((resolve) =>
                setTimeout(async () => {
                    doc = await html2pdf()
                        .set({
                            margin: 5,
                            pagebreak: {
                                mode: ["avoid-all"],
                            },
                            filename: `${this.expandedCard.name}.pdf`,
                        })
                        .from(htmlContent)
                        .outputPdf("blob");
                    resolve({data: "your return data"});
                }, 1000)
            );

            const blobPDF = new Blob([doc], {type: "application/pdf"});
            const file = this.blobToFile(blobPDF, this.expandedCard.name);
            const formData = new FormData();
            formData.append("file", file);
            const response = await axios({
                url: "/uploads/reports/images",
                method: "post",
                data: formData,
            });
            this.showExpand = true;
            const screenshot = {
                available: true,
                location: response.data.file,
                file_name: response.data.file,
                file_mime_type: file.type,
                size: file.size,
            };
            return screenshot;
        },
        blobToFile(theBlob, fileName) {
            //A Blob() is almost a File() - it's just missing the two properties below which we will add
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        },
        async onSaveMedia() {
            const screenshot = await this.createPDF();
            this.setSelectedEntityData({
                all_data: {
                    url: this.expandedCard.name,
                    add_graph: true,
                    screenshot,
                    raw_page_text: document.getElementById("printDataSection").innerText,
                },
                datatype: "passive_media",
                editEntity: true,
                entity: null,
                text: "",
                userDefined: true,
            });
            this.showAddEntityPanel();
            this.isMediaLoading = false;
        },
        onFullScreen(reset = false) {
            let elem = document.getElementById("result-expanded");
            if (reset) {
                elem.style.setProperty("width", "70%", "important");
            } else {
                elem.style.width = null;
            }
            if (elem) {
                elem.style.position = reset ? null : "absolute";
                elem.style.inset = reset ? null : "0";
                elem.style.padding = reset ? "0 0.5rem 0.5rem 0.5rem" : 0;
                elem.style.zIndex = reset ? null : "21";
            }

            let resElem = document.getElementById("result-list-wrapper");
            if (resElem) {
                resElem.style.borderRadius = reset ? "0.375rem" : 0;
                resElem.style.setProperty("height", "100%", "important");
            }

            // let header = document.getElementById("neo-header");
            // header.style.display = "none"; // flex

            let buttonToolbar = document.getElementById("button-toolbar");
            if (buttonToolbar) buttonToolbar.style.display = reset ? null : "none"; // 25

            let osintExpand = document.getElementById("osint--panel--drawer");
            if (osintExpand) osintExpand.style.display = reset ? null : "none"; //2020

            // let versioningTabSwitch = document.getElementById("monitoring-version-switch")
            // if (versioningTabSwitch)
            //     versioningTabSwitch.style.display = reset ? null : "none"; //20

            let monitoringDateRangeSlider = document.getElementsByClassName("monitoring-dateRange-slider");
            if (monitoringDateRangeSlider?.[0]) monitoringDateRangeSlider[0].style.display = reset ? null : "none"; //20

            this.fullview = !reset;
        },
        onClose() {
            if (this.fullview) {
                this.onFullScreen(true);
            } else {
                this.$emit("closeMoreInfo");
            }
        },
        getEntitySource(result) {
            if (result.entity_type.toLowerCase() === "person" || result.entity_type.toLowerCase() === "individual" || result.entity_type.toLowerCase() === "officer") return "name";
            else if (result.api_all_data.e_i && (result.api_all_data.e_i.toLowerCase() === "male" || result.api_all_data.e_i.toLowerCase() === "female")) return "name";
            else return "company_name";
        },
    },
};
